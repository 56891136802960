export const CLIENT_HTTP_TIMEOUT = parseInt(process.env.NEXT_PUBLIC_CLIENT_HTTP_TIMEOUT!) || 2500
export const SERVER_HTTP_TIMEOUT = parseInt(process.env.SERVER_HTTP_TIMEOUT!) || 1000

export class TimeoutError extends Error {}

type Props = { url: string | URL; options?: any; timeout?: number }
export const timeoutFetch = async ({ url, options = {}, timeout }: Props): Promise<Response> => {
    if (timeout === undefined) {
        if (typeof window === 'undefined') {
            timeout = SERVER_HTTP_TIMEOUT
        } else {
            timeout = CLIENT_HTTP_TIMEOUT
        }
    }
    const controller = new AbortController()
    const timeoutId = setTimeout(
        () => controller.abort(new TimeoutError(`Timeout ${timeout} exceeded for url: ${url}`)),
        timeout
    )
    options.signal = controller.signal

    try {
        const response = await fetch(url, options)
        clearTimeout(timeoutId)
        return response
    } catch (error: any) {
        clearTimeout(timeoutId)
        if (error instanceof TimeoutError) {
            // Just log timeouts so they can be monitored and adjusted
            console.error(error)
        }
        throw error
    }
}

export async function fetchAndCatch<T>(func: T) {
    let result
    try {
        result = await func
    } catch (error) {
        // console.error(error)
    }
    return result
}
